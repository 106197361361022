import { ReactComponent as DotsIcon } from "../../assets/icons/more.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import UIPopover from "../../components/Popover";
import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { TableSkeleton } from "../users/Users";
import FullScreenModal from "../../components/FullScreenModal";
import UserIcon from "../../assets/icons/profile-icon.svg";
import { ShowToast } from "../../components/toast";
import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import { Button } from "../../components/Button";
import { ConfirmDelete } from "./DeleteInstitute";

const columnHelper = createColumnHelper<any>();

export default function BlockedInstitutions({
  isOpen,
  handleClose,
  setSelectedUser,
  setIsUserDetailsOpen,
  refetchActiveUsers,
}: {
  isOpen: boolean;
  handleClose: () => void;
  setSelectedUser: (value: any) => void;
  setIsUserDetailsOpen: (value: boolean) => void;
  refetchActiveUsers: () => void;
}) {
  const [tableData, setTableData] = useState<any[] | null>(null);
  const loadMoreRef = useRef<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userRowSelected, setUserRowSelected] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const fetchOrganisations = async ({ pageParam }) => {
    const res = await api.getOrganisations({
      page: pageParam,
      limit: 10,
      type: "all",
      showActive: false,
    });
    return res?.organisations || [];
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["blocked-organizations", isOpen],
    queryFn: fetchOrganisations,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, pageParam) => {
      if (lastPage.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("name", {
      header: "Institution or school name",
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <span>{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.instanceUrl, {
      header: "Login URL",
    }),
    columnHelper.accessor("platform", {
      header: "Platform",
      cell: (info) => (
        <span className="capitalize">{info.getValue() || "-"}</span>
      ),
    }),
    columnHelper.accessor("totalUsers", {
      header: "Users",
      cell: (info) => info.getValue(),
      maxSize: 50,
    }),
    columnHelper.accessor((row) => row.credentials, {
      header: "Client ID",
      cell: (info) => (
        <span>
          {info.getValue() ? JSON.parse(info.getValue())?.clientId : "-"}
        </span>
      ),
    }),
    columnHelper.accessor("credentials", {
      header: "Client Secret",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center">
          <div className="max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden">
            {info.getValue() ? JSON.parse(info.getValue())?.clientSecret : "-"}
          </div>
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="hover:bg-black !rounded-md h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black [&_circle]:hover:fill-white"
              >
                <DotsIcon />
              </Button>
            }
            containerClassName="!z-[50]"
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    api
                      .updateOrganizationStatus(info.row.original.id, true)
                      .then(() => {
                        close();
                        ShowToast({
                          message: "Institution activated successfully",
                          type: "success",
                        });
                        refetch();
                        refetchActiveUsers();
                      })
                      .catch((e: any) => {
                        ShowToast({
                          message:
                            e.response.data.message ||
                            "Failed to activate institution",
                          type: "error",
                        });
                      });
                  }}
                >
                  Activate
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedUser(info.row.original);
                    setUserRowSelected(info.row.original);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<any>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page);
      // Set the combined data into the table data state
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <FullScreenModal isOpen={isOpen} onClose={handleClose}>
      {isDeleteModalOpen && (
        <ConfirmDelete
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          userId={userRowSelected?.id}
          refetch={refetch}
        />
      )}
      <div className="overflow-auto h-full relative max-w-[1100px] mx-auto">
        <h1 className="text-2xl font-inter font-medium mb-5 mt-5">
          Deactivated institution and schools
        </h1>
        <div className="!h-[calc(100%-120px)] overflow-auto">
          <table className="w-full relative">
            <thead className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="sticky top-0">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={clsx(
                        "text-[0.875rem] font-medium text-[#202020] text-start p-0 cursor-pointer"
                      )}
                    >
                      <div
                        className={clsx(
                          "w-full px-5 py-4 border-t border-b border-[#D7D7D7] bg-white flex flex-row items-center gap-1 transition-all",
                          index === headerGroup.headers.length - 1 &&
                            "border-r rounded-tr-[14px] rounded-br-[14px]",
                          index === 0 &&
                            "border-l rounded-tl-[14px] rounded-bl-[14px]"
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {{
                          asc: <ArrowIcon />,
                          desc: <ArrowIcon className="rotate-[180deg]" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row, index) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={clsx(
                          "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                          // last index no border
                          index === table.getRowModel().rows.length - 1 &&
                            "border-b-0"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              {isFetching && (
                <TableSkeleton
                  rows={5}
                  columns={table.getAllColumns().length}
                />
              )}
            </tbody>
          </table>
          {data?.pages?.[0]?.length === 0 && !isFetching && (
            <div className="h-[calc(100%-120px)] flex flex-col justify-center items-center w-full text-sm">
              No deactivated institution and schools found
            </div>
          )}
        </div>

        <div className="">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </FullScreenModal>
  );
}
