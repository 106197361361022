import { ReactComponent as FilterIcon } from "../../assets/icons/filters.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/more.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import UserIcon from "../../assets/icons/profile-icon.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import UIPopover from "../../components/Popover";
// import UserDetails, { ConfirmBlock, ConfirmDelete } from "./UserDetails";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { ShowToast } from "../../components/toast";
// import BlockedUsers from "./BlockedUsers";
import clsx from "clsx";
import Filters from "./Filter";
import { SelectedFilters } from "./SelectedFilters";
import { Button } from "../../components/Button";
import SearchComponent from "../../components/Search";
import { InstitutionForm } from "./AddInstitution";
import InstitutionRequests from "./InstitutionRequests";
import { ConfirmDelete } from "./DeleteInstitute";
import { ConfirmBlock } from "./BlockInstitute";
import BlockedInstitutions from "./BlockedInstitutions";

const columnHelper = createColumnHelper<any>();

export default function Institutions() {
  const [sortBy, setSortBy] = useState<"name" | undefined>();
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any | null>(null);
  const [search, setSearch] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [isBlockedUsers, setIsBlockedUsers] = React.useState(false);
  const [requestedInstitutionModal, setRequestedInstitutionModal] =
    React.useState(false);
  const loadMoreRef = useRef<any>(null);
  const [filters, setFilters] = useState<{
    date: { startDate?: string; endDate?: string };
    types?: string[];
    selectedDateFilter?: string;
  }>();
  const [tempFilters, setTempFilters] = React.useState<{
    date: { startDate: string; endDate: string };
    types: string[];
    selectedDateFilter: string;
  }>({
    date: { startDate: "", endDate: "" },
    types: [],
    selectedDateFilter: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState<any | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const fetchUsers = async ({ pageParam }) => {
    const res = await api.getOrganisations({
      page: pageParam,
      limit: 10,
      searchKeyword: search,
      platform: filters?.types || [],
      type: "all",
      sortBy,
      sortOrder,
    });
    return res?.organisations || [];
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["organizations", search, filters, sortBy, sortOrder],
    queryFn: fetchUsers,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, pageParam) => {
      if (lastPage.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("name", {
      header: ({ column }) => {
        return (
          <button
            onClick={() => {
              if (sortBy === "name") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("name");
                setSortOrder("ASC");
              }
            }}
            className="p-0 flex items-center gap-[2px]"
          >
            Institution or school name
            {sortBy === "name" ? (
              sortOrder === "ASC" ? (
                <ArrowDownIcon className="w-4 h-4 rotate-[180deg] ml-2" />
              ) : (
                <ArrowDownIcon className="w-4 h-4 ml-2" />
              )
            ) : null}
          </button>
        );
      },
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <span>{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.instanceUrl, {
      header: "Login URL",
    }),
    columnHelper.accessor("platform", {
      header: "Platform",
      cell: (info) => (
        <span className="capitalize">{info.getValue() || "-"}</span>
      ),
    }),
    columnHelper.accessor("totalUsers", {
      header: "Users",
      cell: (info) => info.getValue(),
      maxSize: 50,
    }),
    columnHelper.accessor((row) => row.credentials, {
      header: "Client ID",
      cell: (info) => (
        <span>
          {info.getValue() ? JSON.parse(info.getValue())?.clientId : "-"}
        </span>
      ),
    }),
    columnHelper.accessor("credentials", {
      header: "Client Secret",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center">
          <div className="max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden">
            {info.getValue() ? JSON.parse(info.getValue())?.clientSecret : "-"}
          </div>
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="hover:bg-black !rounded-md h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black [&_circle]:hover:fill-white"
              >
                <DotsIcon />
              </Button>
            }
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    setSelectedOrganisation(info.row.original);
                    setOpen(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="text-start"
                  onClick={() => {
                    setIsBlockModalOpen(true);
                    setSelectedUser(info.row.original);
                  }}
                >
                  Deactivate
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedUser(info.row.original);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<any>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page);
      // Set the combined data into the table data state
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const hasFilters = useMemo(() => {
    return filters?.date.startDate || (filters?.types?.length || 0) > 0;
  }, [filters]);

  const onClose = () => setOpen(false);

  return (
    <div className="flex flex-col gap-4">
      {/* <UserDetails
        isOpen={isUserDetailsOpen}
        setIsOpen={setIsUserDetailsOpen}
        userData={selectedUser!}
        refetchAllUsers={refetch}
      /> */}

      <BlockedInstitutions
        isOpen={isBlockedUsers}
        handleClose={() => setIsBlockedUsers(false)}
        setSelectedUser={setSelectedUser}
        setIsUserDetailsOpen={setIsUserDetailsOpen}
        refetchActiveUsers={refetch}
      />
      <InstitutionRequests
        isOpen={requestedInstitutionModal}
        handleClose={() => setRequestedInstitutionModal(false)}
        setSelectedUser={setSelectedUser}
        setIsUserDetailsOpen={setIsUserDetailsOpen}
        refetchActiveUsers={refetch}
      />
      {isDeleteModalOpen && selectedUser && (
        <ConfirmDelete
          onClose={() => setIsDeleteModalOpen(false)}
          userId={selectedUser.id}
          refetch={() => {
            refetch();
          }}
        />
      )}
      {isBlockModalOpen && selectedUser && (
        <ConfirmBlock
          onClose={() => setIsBlockModalOpen(false)}
          userId={selectedUser.id}
          refetch={() => {
            queryClient.invalidateQueries({ queryKey: ["organizations"] });
            queryClient.invalidateQueries({
              queryKey: ["blocked-organizations"],
            });
          }}
          isActive={true}
        />
      )}

      <div className="flex flex-row justify-between">
        <h1 className="text-black text-2xl font-bold leading-[28.8px]">
          Institution and schools
        </h1>
        <div className="flex items-center gap-2">
          <Button
            variant="link"
            onClick={() => setIsBlockedUsers(!isBlockedUsers)}
          >
            Deactivated Institutions
          </Button>
          <Button
            variant="link"
            onClick={() =>
              setRequestedInstitutionModal(!requestedInstitutionModal)
            }
          >
            Requested Institution
          </Button>
        </div>
      </div>
      <div className="flex flex-row mt-2 mb-1 justify-between items-center">
        <SearchComponent
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search"
          value={search}
        />
        <div className="flex flex-row gap-2 items-center">
          <Filters
            setFilters={setFilters}
            tempFilters={tempFilters}
            setTempFilters={setTempFilters}
          />

          <Button
            className="w-[150px]"
            variant="primary"
            onClick={() => setOpen(true)}
          >
            Add Institution
          </Button>
          <InstitutionForm
            onUpdate={() => {
              refetch();
              setSelectedOrganisation(null);
            }}
            open={open}
            onClose={onClose}
            organisation={selectedOrganisation}
          />
        </div>
      </div>
      <SelectedFilters
        filters={filters}
        setFilters={setFilters}
        hasFilters={
          filters?.date.startDate || (filters?.types?.length || 0) > 0
        }
        setTempFilters={setTempFilters}
      />

      <div
        className={`overflow-auto ${
          hasFilters ? "h-[calc(100vh-260px)]" : "h-[calc(100vh-190px)]"
        } relative flex flex-col`}
      >
        <table className="w-full relative">
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead className="w-full">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sticky top-0">
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={clsx(
                      "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                    )}
                  >
                    <div
                      className={clsx(
                        "w-full px-5 h-[55px] border-t border-b border-[#D7D7D7] bg-white flex flex-row items-center gap-1 transition-all",
                        index === headerGroup.headers.length - 1 &&
                          "border-r rounded-tr-[14px] rounded-br-[14px]",
                        index === 0 &&
                          "border-l rounded-tl-[14px] rounded-bl-[14px]"
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {!isLoading &&
              table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                        // last index no border
                        index === table.getRowModel().rows.length - 1 &&
                          "border-b-0"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}

            {isFetching && (
              <TableSkeleton rows={5} columns={table.getAllColumns().length} />
            )}
          </tbody>
        </table>
        {!isLoading && !isFetching && tableData.length === 0 && (
          <div className="flex-1 flex justify-center items-center">
            <span className="text-[#999999] text-[0.875rem] font-medium">
              No data available
            </span>
          </div>
        )}
        <div className="">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </div>
  );
}

export function TableSkeleton({
  rows,
  columns,
}: {
  rows: number;
  columns: number;
}) {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={`skeleton-row-${rowIndex}`} className="animate-pulse">
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td
              key={`skeleton-cell-${rowIndex}-${colIndex}`}
              className="px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]"
            >
              <div className="w-full h-4 bg-gray-300 rounded-md"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
