import LogoIcon from "../assets/icons/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as SideBarHue } from "../assets/icons/sidebar-hue.svg";

import { clearTokenAndUser } from "../helpers/utils";
import { Button } from "./Button";

export default function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearTokenAndUser();
    localStorage.clear();
    navigate("/login");
  };

  // Desktop Sidebar
  return (
    <div className="lg:flex flex-col h-[100dvh] relative hidden min-w-[300px] p-3 border-r border-[#C2D3C9]">
      <div className="mb-5 z-[1]">
        <Link
          to="/home"
          className="flex font-outfit font-[500] text-[1.125rem] items-center gap-1"
        >
          <img src={LogoIcon} alt="logo" className="h-[55px]" />
        </Link>
      </div>

      <div className="grid gap-[0.375rem] z-[1]">
        <CustomLink to="/dashboard" activePath="/dashboard" label="Dashboard" />
        <CustomLink to="/users" activePath="/users" label="Manage users" />
        <CustomLink
          to="/institutions"
          activePath="/institutions"
          label="Institution and schools"
        />

        <div className="absolute bottom-3 w-[calc(100%-24px)] p-3 border border-[#DDD] rounded-lg flex flex-row justify-between items-center gap-4">
          <div className="flex items-center gap-2.5">
            <img
              src={"/admin.svg"}
              alt="admin"
              className="w-[30px] h-[30px] rounded-md"
            />
            <span className="font-medium leading-[110%]">Admin</span>
          </div>
          <button onClick={handleLogout}>
            <LogoutIcon width={24} height={24} />
          </button>
        </div>
      </div>
    </div>
  );
}

function CustomLink({
  to,
  activePath,
  label,
}: {
  to: string;
  activePath: string;
  label: string;
}) {
  const isActive = window.location.pathname === activePath;

  return (
    <Link to={to}>
      <Button
        className={clsx(
          "!px-[1.375rem] !rounded-md py-3.5 hover:!bg-[#E8F1FF] flex flex-row !justify-start gap-2 w-full items-center border-[1px] border-transparent",
          {
            "!bg-[#E8F1FF] border-[1px] border-solid !border-[rgba(42,123,255,0.14)] shadow-[0px_1px_4px_0px_#64B6671C_inset]":
              isActive,
          }
        )}
        variant={isActive ? "primary" : "outline"}
      >
        <span
          className={clsx(
            { "!text-[#666]": !isActive },
            "text-black font-[500] text-[1rem]"
          )}
        >
          {label}
        </span>
      </Button>
    </Link>
  );
}
