import clsx from "clsx";
import { subscriptions } from "../helpers/consts";

export default function SubscriptionBadge({
  plan,
  className,
}: {
  plan: string;
  className?: string;
}) {
  const subscription = subscriptions.find((s) => s.id === plan);

  return (
    <div
      className={clsx(
        `flex flex-row text-center justify-center text-[13px] font-medium leading-[18px] items-center gap-2  py-1.5 rounded-md border border-solid w-[72px]`,
        className
      )}
      style={{
        backgroundColor: subscription?.bg,
        color: subscription?.color,
        borderColor: subscription?.border,
      }}
    >
      <span>{subscription?.name}</span>
    </div>
  );
}
