import { useState } from "react";
import { api } from "../../helpers/api";
import { ShowToast } from "../../components/toast";
import { Button } from "../../components/Button";
import { PopupModal } from "../../components/PopupModal";

export function ConfirmDelete({
  onClose,
  userId,
  refetch,
  isRequest = false,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  isRequest?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = () => {
    setIsLoading(true);
    const text = isRequest ? " request" : "";
    const API = isRequest
      ? api.deleteOrganizationRequest
      : api.deleteOrganization;
    API(userId)
      .then(() => {
        onClose();
        ShowToast({
          message: `Institution${text} deleted successfully`,
          type: "success",
        });
        refetch && refetch();
        setIsLoading(false);
      })
      .catch(() => {
        ShowToast({
          message: `Failed to delete institution${text}`,
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to delete this institution? This action cannot be
        undone.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={handleDelete}
          loading={isLoading}
          variant="primary"
        >
          Delete
        </Button>
      </div>
    </PopupModal>
  );
}
