export function SelectInput({
  data,
  ...props
}: React.InputHTMLAttributes<HTMLSelectElement> & {
  data: { value: string; label: string }[];
}) {
  return (
    <div className="w-full">
      <select
        {...props}
        className={`!outline-none w-full focus:bg-white rounded-lg border border-lightGray focus:border-grey2 p-[18px] text-base text-black ${
          props.className || ""
        }`}
      >
        <option value="" disabled>
          {props.placeholder || "Select an option"}
        </option>
        {data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}
