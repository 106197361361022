import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as MoneyIcon } from "../../assets/icons/money.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import Chart from "./Chart";
import { Link } from "react-router-dom";
import { useUser } from "../auth/context/user-context";
import RecentSubscriptions from "./RecentSubscriptions";
import { formatNumber } from "../../helpers/utils";

export default function Dashboard() {
  const { userInfo: user } = useUser() as any;
  const { data, isLoading } = useQuery({
    queryKey: ["dashboard", user?.id],
    queryFn: () => api.dashboard(user!.id),
    // enabled: !!user,
  });

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-black text-2xl font-bold leading-[28.8px]">
        Dashboard
      </h1>
      {!isLoading && (
        <div className="flex flex-row gap-[1.125rem] flex-wrap">
          <div className="flex gap-4 p-6 flex-1 items-center rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
            <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
              <UsersIcon />
            </div>
            <Link to="/users" className="flex flex-col gap-1">
              <span className=" font-medium leading-[113.6%] text-base">
                Total Users
              </span>
              <span className="font-bold text-2xl text-[#101828]">
                {data?.totalUsers}
              </span>
            </Link>
          </div>
          <div className="flex gap-4 p-6 flex-1 items-center rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
            <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
              <MoneyIcon />
            </div>
            <div className="flex flex-col gap-1">
              <span className=" font-medium leading-[113.6%] text-base">
                Total Revenue
              </span>
              <span className="font-bold text-2xl text-[#101828]">
                ${formatNumber(data?.totalRevenue || 0)}
              </span>
            </div>
          </div>
          <div className="flex gap-4 p-6 flex-1 items-center rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
            <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
              <MoneyIcon />
            </div>
            <div className="flex flex-col gap-1">
              <span className=" font-medium leading-[113.6%] text-base">
                MRR
              </span>
              <span className="font-bold text-2xl text-[#101828]">
                ${formatNumber(data?.totalRevenue || 0)}
              </span>
            </div>
          </div>
          <div className="flex gap-4 py-6 pl-6 pr-5 flex-1 items-center rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
            <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
              <CalendarIcon />
            </div>
            <div className="flex-1 flex flex-col gap-1">
              <span className="whitespace-nowrap font-medium text-base leading-[113.6%]">
                Active subscriptions
              </span>
              <span className="font-bold text-2xl text-[#101828]">
                {data?.totalSubscribers || 0}
              </span>
            </div>
          </div>
        </div>
      )}
      {isLoading && <LoadingSkeleton />}

      <div className="flex flex-row gap-[1.125rem] flex-wrap">
        <Chart />
        <RecentSubscriptions />
      </div>
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <div className="flex flex-row gap-[1.125rem] flex-wrap animate-pulse">
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
          <UsersIcon />
        </div>
        <div className="flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">
            Total Users
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
          <MoneyIcon />
        </div>
        <div className="flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">
            Total Revenue
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
          <MoneyIcon />
        </div>
        <div className="flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">MRR</span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-[#EFF2FF] w-16 rounded-full h-16 flex items-center justify-center">
          <CalendarIcon />
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">
            Active subscriptions
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    </div>
  );
}
