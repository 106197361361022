import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { Button } from "../../../components/Button";

export function Header({
  showSignIn = false,
  showSignUp = false,
  isProvider = false,
}) {
  return (
    <div className="px-4 md:px-10 pt-4 bg-white/[0.64] backdrop-blur-[27px] justify-between items-center flex">
      <div className=" justify-center items-center flex">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  );
}
