import { useState } from "react";
import { api } from "../../helpers/api";
import { ShowToast } from "../../components/toast";
import { Button } from "../../components/Button";
import { PopupModal } from "../../components/PopupModal";

export function ConfirmBlock({
  onClose,
  userId,
  refetch,
  isActive,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  isActive: boolean;
}) {
  const [blocking, setBlocking] = useState(false);
  const handleBlock = () => {
    setBlocking(true);
    api
      .updateOrganizationStatus(userId, isActive ? false : true)
      .then(() => {
        setBlocking(false);
        onClose();
        ShowToast({
          message: isActive
            ? "Institute deactivated successfully"
            : "Institute activated successfully",
          type: "success",
        });
        refetch && refetch();
      })
      .catch((err) => {
        setBlocking(false);
        ShowToast({
          message:
            err.response.data.message ||
            `Failed to ${isActive ? "block" : "unblock"} institution`,
          type: "error",
        });
      });
  };

  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to {isActive ? "block" : "unblock"} this
        institution?
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button
          variant="outline"
          className="px-8"
          onClick={onClose}
          disabled={blocking}
        >
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={handleBlock}
          loading={blocking}
          variant="primary"
        >
          {isActive ? "Block" : "Unblock"}
        </Button>
      </div>
    </PopupModal>
  );
}
