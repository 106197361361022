const statusList = [
  {
    name: "pending",
    title: "Pending",
    bg: "bg-[#FAE7D5]",
    text: "text-[#A76313]",
    border: "border-[rgba(167,134,19,0.15)]",
  },
  {
    name: "approved",
    title: "Approved",
    bg: "bg-[#E1FAD5]",
    border: "border-[rgba(19,167,43,0.15]",
    text: "text-[#13A719]",
  },
  {
    name: "declined",
    title: "Declined",
    bg: "bg-[#FAE7D5]",
    border: "border-[rgba(167,19,19,0.15)]",
    text: "text-[#A71313]",
  },
];
export function Status({ status }) {
  const statusData = statusList.find((item) => item.name === status);
  return (
    <div
      className={`text-[13px] rounded-[6px] px-2.5 py-1.5 font-medium border-[0.5px] ${statusData?.bg} ${statusData?.border} ${statusData?.text}`}
    >
      <span>{statusData?.title}</span>
    </div>
  );
}
