import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { Link } from "react-router-dom";
import { useState } from "react";
import SubscriptionCard, {
  SubscriptionCardSkeleton,
} from "./SubscriptionCardSkeletion";

export default function RecentSubscriptions() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["subscribers"],
    queryFn: () =>
      api.getUsers({
        page: 0,
        limit: 10,
        type: "SUBSCRIBERS",
      }),
  });

  return (
    <div className="flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)] h-[75dvh] flex flex-col !p-0 !gap-0">
      {/* <EntityDetails
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        data={selectedEntity}
        refetchEntityDetails={refetch}
      /> */}
      <div className="flex flex-row justify-between items-center border-b border-b-[#D7D7D7] p-6">
        <span className="text-xl leading-[113.6%] font-bold">
          Recent Subscriptions
        </span>
        {/* <Link
          to="/users"
          className="px-4 py-2 rounded-[20px] bg-[#F3F3F3] text-[1rem] leading-[113.6%] flex flex-row gap-1 items-center h-fit"
        >
          All forms
        </Link> */}
      </div>
      {!isLoading && (
        <div className="flex flex-col overflow-auto">
          {data?.map((subscription: any) => (
            <button
              onClick={() => {
                setIsOpen(true);
                setSelectedEntity(subscription);
              }}
              className="text-start"
            >
              <SubscriptionCard
                key={subscription.id}
                subscription={subscription}
                noBorder={data[data.length - 1].id === subscription.id}
              />
            </button>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="flex flex-col overflow-auto">
          <SubscriptionCardSkeleton />
          <SubscriptionCardSkeleton />
          <SubscriptionCardSkeleton />
        </div>
      )}
    </div>
  );
}
