import { platform } from "os";
import client from "./client";

export const api = {
  login: (data: any) => {
    return client.post("/auth/login", {
      ...data,
      userType: "A",
    });
  },
  dashboard: (id: string) => {
    return client.get(`/users/${id}/dashboard`).then((res) => res.data);
  },
  getUser: ({ id }: { id: string }) => {
    return client.get(`/users/${id}`);
  },
  // /users/{id}/reports
  getUserRevenue: ({
    id,
    type,
    duration,
  }: {
    id: string;
    type: "USERS_COUNT" | "REVENUE";
    duration: "CURRENT_WEEK" | "CURRENT_MONTH" | "CURRENT_YEAR";
  }) => {
    return client
      .get(`/users/${id}/reports`, {
        params: {
          type,
          duration,
        },
      })
      .then((res) => res.data);
  },
  getOrganisations: ({
    page,
    limit,
    searchKeyword,
    platform,
    type,
    showActive = true,
    sortBy,
    sortOrder,
  }: {
    page?: number;
    limit?: number;
    searchKeyword?: string;
    platform?: string[];
    type?: string;
    showActive?: boolean;
    sortBy?: string;
    sortOrder?: string;
  }) => {
    let params = {
      page,
      limit,
    };

    params["showActive"] = showActive;
    if (searchKeyword) params["searchKeyword"] = searchKeyword;
    if (type) params["type"] = type;

    if (sortBy) {
      params["sortBy"] = sortBy;
    }
    if (sortOrder) {
      params["sortOrder"] = sortOrder;
    }
    if (platform && platform?.length > 0)
      params["platform"] = platform.join(",");

    // Serialize params to query string
    const queryString = Object.entries(params)
      .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return client.get(`/organisations?${queryString}`).then((res) => res.data);
  },
  createOrganisation: (data: any) => {
    return client.post("/organisations", data);
  },
  updateOrganisation: (id: string, data: any) => {
    return client.patch(`/organisations/${id}`, data);
  },
  updateOrganizationStatus: (id: string, isActive: boolean) => {
    return client.patch(`/organisations/${id}/status`, {
      isActive,
    });
  },
  approveOrganization: (id: string) => {
    return client.patch(`/organisations/${id}/request/approve`);
  },
  declineOrganization: (id: string) => {
    return client.patch(`/organisations/${id}/request/decline`);
  },
  deleteOrganization: (id: string) => {
    return client.delete(`/organisations/${id}`);
  },
  deleteOrganizationRequest: (id: string) => {
    return client.delete(`/organisations/${id}/request`);
  },
  getUsers: ({
    page,
    limit,
    search,
    showActive = true,
    filters,
    type,
    sortBy,
    sortOrder,
  }: {
    page: number;
    limit: number;
    search?: string;
    showActive?: boolean;
    filters?: any;
    type?: string;
    sortBy?: string;
    sortOrder?: string;
  }) => {
    let url = `/users?page=${page}&limit=${limit}&showActive=${showActive}`;
    if (type) {
      url += `&type=${type}`;
    }
    if (search) {
      url += `&searchKeyword=${search}`;
    }
    if (filters?.date?.startDate && filters?.date?.endDate) {
      url += `&startDate=${filters?.date?.startDate}&endDate=${filters?.date?.endDate}`;
    }

    if (sortBy) {
      url += `&sortBy=${sortBy}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    if (filters?.types && filters?.types?.length > 0) {
      const types = filters.types.join(",");
      if (types?.length > 0) {
        url += `&tiers=${filters.types.join(",")}`;
      }
    }
    return client.get(url).then((res) => res.data);
  },
  updateUser: (id: string, isActive: boolean) => {
    return client.patch(`/users/${id}/status`, {
      isActive,
    });
  },
  deleteUser: (id: string) => {
    return client.delete(`/users/${id}`);
  },
  getEntity: (id: string) => {
    return client.get(`/business-entities/${id}`).then((res) => res.data);
  },
  updateEntityStatus: ({ status, id }: { status: number; id: string }) => {
    return client.patch(`/business-entities/${id}`, { status });
  },
  archiveEntity: (id: string) => {
    return client.delete(`/business-entities/${id}`);
  },
};
