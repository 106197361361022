import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import { Login } from "./pages/auth/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import Institutions from "./pages/institutions/Institutions";

const RoutesList = () => {
  return (
    <Routes>
      <Route>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="/" element={<Main />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="institutions" element={<Institutions />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};
export default RoutesList;
