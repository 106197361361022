export const subscriptions = [
  {
    id: "SM",
    name: "6 Month",
    bg: "#ECD5FA",
    color: "#6F13A7",
    border: "#8013A726",
  },
  {
    id: "TM",
    name: "3 Month",
    bg: "#D5EFFA",
    color: "#139EA7",
    border: "#139EA726",
  },
  {
    id: "OM",
    name: "1 Month",
    bg: "#FAF6D5",
    color: "#A76313",
    border: "rgba(167,99,19,0.15)",
  },
];
