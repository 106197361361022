import React, { useEffect } from "react";
import { getUser, setUser } from "../../../helpers/utils";
import { ShowToast } from "../../../components/toast";
import { api } from "../../../helpers/api";

export const UserContext = React.createContext({});

const ACTIVE_STATUS_INTERVAL = 1000 * 60; // 1 minute

export function UserProvider({ children }) {
  const [userInfo, setUserInfo] = React.useState({});
  const [userInfoLoading, setUserInfoLoading] = React.useState(false);
  const [patientCount, setPatientCount] = React.useState(0);
  const [selectedProviderToMessage, setSelectedProviderToMessage] =
    React.useState<any>(null);
  const user = getUser();
  const activeStatusRef = React.useRef<any>(null);
  const patientCountRef = React.useRef<any>(null);

  const fetchUser = async (userId: string) => {
    try {
      setUserInfoLoading(true);
      const response = await api.getUser({ id: userId });
      setUserInfoLoading(false);
      setUserInfo({
        ...response,
      });
      setUser({
        ...response,
      });
    } catch (error) {
      ShowToast({
        message: "Something went wrong while fetching user info",
        type: "error",
      });
    }
  };

  const values = {
    userInfo,
    setUserInfo,
    fetchUser,
    loading: userInfoLoading,
    selectedProviderToMessage,
    setSelectedProviderToMessage,
    patientCount,
  };
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
