import clsx from "clsx";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import moment from "moment";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import UserIcon from "../../assets/icons/profile-icon.svg";

export default function SubscriptionCard({
  className,
  subscription,
  noBorder,
}: {
  className?: string;
  subscription?: any;
  noBorder?: boolean;
}) {
  return (
    <div
      className={clsx(
        "flex flex-row items-center py-4 px-5 gap-4 border-b border-b-[#D7D7D7] justify-between",
        className,
        {
          "border-b-0": noBorder,
        }
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <div className="w-[50px] h-[50px] rounded-full">
          <img
            src={subscription?.profileImg || UserIcon}
            className="w-full h-full rounded-full"
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-lg leading-normal font-medium">
            {subscription?.fullname}
          </span>
          <div className="flex flex-row gap-1 items-center">
            <ClockIcon width={18} height={18} />
            <span className="text-sm text-[#666]">Registered in:</span>
            <span className="text-sm font-medium text-[#666]">
              {moment(subscription?.createdAt).format("DD MMM, YYYY")}
            </span>
          </div>
        </div>
      </div>
      <SubscriptionBadge plan={subscription.tier} />
    </div>
  );
}

export function SubscriptionCardSkeleton({
  className,
  noBorder,
}: {
  className?: string;
  noBorder?: boolean;
}) {
  return (
    <div
      className={clsx(
        "flex flex-row items-center py-4 px-5 gap-4 border-b border-b-[#D7D7D7] justify-between animate-pulse",
        className,
        {
          "border-b-0": noBorder,
        }
      )}
    >
      <div className="flex flex-row gap-4">
        <div className="w-[50px] h-[50px] rounded-full">
          <img
            src="https://via.placeholder.com/50x50"
            className="w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <span className="text-lg leading-normal font-medium w-36 h-4 bg-gray-300 rounded-md"></span>
          <div className="flex flex-row gap-1 items-center">
            <ClockIcon width={18} height={18} />
            <span className="text-sm text-[#666] w-24 h-3 bg-gray-300 rounded-md"></span>
          </div>
        </div>
      </div>
      <div className="w-24 h-5 bg-gray-300 rounded-md"></div>
    </div>
  );
}
