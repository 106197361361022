import { useEffect, useState } from "react";
import { PopupModal } from "../../components/PopupModal";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import ChevronDown from "../../assets/icons/chevron-down.svg";

import { SelectInput } from "../../components/SelectInput";
import { api } from "../../helpers/api";
import { ShowToast } from "../../components/toast";

type Organization = {
  name: string;
  platform: string;
  redirectUri: string;
  credentials: {
    clientId: string;
    clientSecret: string;
  };
  id?: string;
  instanceUrl?: string;
};

export function InstitutionForm({
  open,
  onClose,
  onUpdate,
  organisation,
}: {
  onUpdate: () => void;
  open: boolean;
  onClose: () => void;
  organisation?: any;
}) {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<Organization>({
    name: "",
    platform: "",
    redirectUri: "",
    credentials: {
      clientId: "",
      clientSecret: "",
    },
    instanceUrl: "",
  });

  const handleClose = () => {
    onClose();
    setForm({
      name: "",
      platform: "",
      redirectUri: "",
      credentials: {
        clientId: "",
        clientSecret: "",
      },
      instanceUrl: "",
    });
  };

  const handleSubmit = async () => {
    // Add institution logic here
    try {
      setSaving(true);
      if (organisation?.id) {
        const response = await api.updateOrganisation(organisation.id, form);
        if ([200, 201].includes(response.status)) {
          ShowToast({
            type: "success",
            message: "Institution updated successfully",
          });
        }
      } else {
        const response = await api.createOrganisation(form);
        if ([200, 201].includes(response.status)) {
          ShowToast({
            type: "success",
            message: "Institution created successfully",
          });
        }
      }
      setSaving(false);
      handleClose();
      onUpdate();
    } catch (error) {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (organisation) {
        const json = JSON.parse(organisation?.credentials || "{}");
        setForm({
          name: organisation.name,
          platform: organisation.platform,
          redirectUri: organisation.redirectUri,
          instanceUrl: organisation.instanceUrl,
          credentials: {
            clientId: json?.clientId || "",
            clientSecret: json?.clientSecret || "",
          },
          id: organisation.id,
        });
      } else {
        setForm({
          name: "",
          platform: "",
          redirectUri: "",
          credentials: {
            clientId: "",
            clientSecret: "",
          },
          instanceUrl: "",
        });
      }
    }
  }, [open, organisation]);

  const disabled =
    !form.name ||
    !form.platform ||
    !form.instanceUrl ||
    !form.redirectUri ||
    !form?.credentials?.clientId ||
    !form?.credentials?.clientSecret;

  return (
    <>
      {open && (
        <PopupModal onClose={handleClose} contentClassName="w-[500px] mx-auto">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">
              {form.id ? "Edit" : "Add"} Institution
            </h1>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col gap-5 pr-2 max-h-[calc(100vh-300px)] overflow-auto">
            <div className="flex flex-col gap-1.5">
              <label className="text-sm text-[#222]">Platform </label>
              <SelectInput
                data={[
                  { label: "Canvas", value: "canvas" },
                  { label: "Moodle", value: "moodle" },
                  { label: "Blackboard", value: "blackboard" },
                  { label: "Brightspace", value: "brightspace" },
                ]}
                className="!rounded-full !py-[11px] appearance-none"
                value={form.platform}
                onChange={(e) => setForm({ ...form, platform: e.target.value })}
                style={{
                  backgroundImage: `url(${ChevronDown})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "97% 55%",
                }}
                placeholder="Select platform"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm text-[#222]">
                School/Institution name{" "}
              </label>
              <TextInput
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                placeholder="Enter your school or institution name"
                className="!rounded-full !py-[11px]"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm text-[#222]">URL </label>
              <TextInput
                value={form.instanceUrl}
                onChange={(e) =>
                  setForm({ ...form, instanceUrl: e.target.value })
                }
                placeholder="Enter url"
                className="!rounded-full !py-[11px]"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm text-[#222]">Redirect URL </label>
              <TextInput
                value={form.redirectUri}
                onChange={(e) =>
                  setForm({ ...form, redirectUri: e.target.value })
                }
                placeholder="Enter redirect url"
                className="!rounded-full !py-[11px]"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm text-[#222]">Client ID</label>
              <TextInput
                value={form?.credentials?.clientId}
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: {
                      clientId: e.target.value,
                      clientSecret: form?.credentials?.clientSecret,
                    },
                  })
                }
                placeholder="Enter client ID"
                className="!rounded-full !py-[11px]"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm text-[#222]">Client Secret</label>
              <TextInput
                value={form?.credentials?.clientSecret}
                onChange={(e) =>
                  setForm({
                    ...form,
                    credentials: {
                      clientId: form?.credentials?.clientId,
                      clientSecret: e.target.value,
                    },
                  })
                }
                placeholder="Enter client Secret"
                className="!rounded-full !py-[11px]"
              />
            </div>
          </div>
          <Button
            disabled={disabled || saving}
            loading={saving}
            onClick={handleSubmit}
            variant="primary"
          >
            Add Institution
          </Button>
        </PopupModal>
      )}
    </>
  );
}
