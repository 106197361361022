import { Loading } from "../Loading/Loading";

type Props = {
  variant: "primary" | "outline" | "link";
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export function Button({ variant, className, loading, ...props }: Props) {
  return (
    <button
      className={`transition duration-75 rounded-full text-sm font-medium leading-normal py-3 px-5 flex gap-1.5 items-center justify-center
      ${
        variant === "outline"
          ? "border border-[#D7D7D7] !text-primary hover:!text-white"
          : ""
      }
      ${
        variant === "primary"
          ? "bg-primary border border-[#266FE5] shadow-[0px_4px_11.2px_0px_rgba(255,255,255,0.25)_inset]"
          : ""
      }
      ${
        variant === "link"
          ? `!bg-transparent text-primary ${
              !props.disabled ? "hover:!text-black" : "opacity-50"
            } !p-0`
          : "text-white"
      }
      ${
        props.disabled || loading
          ? "cursor-not-allowed !bg-[#C0C0C0] border-0"
          : "hover:bg-primary/80"
      }
      ${className || ""}
    `}
      {...props}
    >
      {props.children}
      {loading && <Loading />}
    </button>
  );
}
